.autoComplete_wrapper {
  display: inline-block;
  position: relative;
}

.autoComplete_wrapper > input {
  width: 370px;
  height: 40px;
  padding-left: 10px;
  font-size: 1rem;
  color: rgb(116, 116, 116);
  border-radius: 4px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  outline: none;
}

.autoComplete_wrapper > input::placeholder {
  color: rgba(123, 123, 123, 0.5);
  transition: all 0.3s ease;
}

.autoComplete_wrapper > ul {
  position: absolute;
  max-height: 226px;
  overflow-y: scroll;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0.5rem 0 0 0;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid rgba(33, 33, 33, 0.1);
  z-index: 1000;
  outline: none;
}

.autoComplete_wrapper > ul > li {
  padding: 10px 20px;
  list-style: none;
  text-align: left;
  font-size: 16px;
  color: #212121;
  transition: all 0.1s ease-in-out;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease;
}

.autoComplete_wrapper > ul > li::selection {
  color: rgba(#ffffff, 0);
  background-color: rgba(#ffffff, 0);
}

.autoComplete_wrapper > ul > li:hover {
  cursor: pointer;
  background-color: rgba(123, 123, 123, 0.1);
}

.autoComplete_wrapper > ul > li mark {
  background-color: transparent;
  color: rgba(255, 122, 122, 1);
  font-weight: bold;
}

.autoComplete_wrapper > ul > li mark::selection {
  color: rgba(#ffffff, 0);
  background-color: rgba(#ffffff, 0);
}

.autoComplete_wrapper > ul > li[aria-selected="true"] {
  background-color: rgba(123, 123, 123, 0.1);
}

@media only screen and (max-width: 600px) {
  .autoComplete_wrapper > input {
    width: 18rem;
  }
}
